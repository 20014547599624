import React from 'react';

import { Layout, SEO } from 'components';
import { Therapy } from 'containers';

const TherapyPage = () => (
  <Layout>
    <SEO title='Therapy' />
    <Therapy />
  </Layout>
);

export default TherapyPage;
